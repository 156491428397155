<template>
    <q-drawer
        ref="rootElRef"
        v-model="isVisible"
        :width="width"
        :breakpoint="960"
        @hide="onHide"
        class="left-menu-component"
        :aria-label="localize('Главное меню')"
        :class="{ 'new-year': isNewYear }"
    >
        <div class="content">
            <div class="logo" aria-hidden="true">
                <router-link to="/" aria-hidden="true" tabindex="-1">
                    <OdinIcon aria-hidden="true" />
                </router-link>
            </div>

            <nav class="menu-content" :class="{ 'open-support': isVisibleSupportMenu }">
                <q-scroll-area
                    ref="scrollAreaRef"
                    @scroll="onScroll"
                    style="height: 100%"
                    :class="{ viewScroll: !isViewScroll }"
                >
                    <div
                        class="menu-item"
                        :class="{ active: getRoutePageName(RoutePageNameEnum.Dashboard) === currentPage }"
                    >
                        <router-link class="menu-item__link" :to="getPageUrl(RoutePageNameEnum.Dashboard)">
                            <DashboardIcon aria-hidden="true" />
                            <div class="menu-title" :class="getClassForSelectedMenu(RoutePageNameEnum.Dashboard)">{{ localize('Дашборд') }}</div>
                        </router-link>
                    </div>

                    <div
                        v-if="isShowSchedule"
                        class="menu-item"
                        :class="{ active: getRoutePageName(RoutePageNameEnum.Schedule) === currentPage }"
                    >
                        <router-link class="menu-item__link" :to="getPageUrl(RoutePageNameEnum.Schedule)">
                            <ScheduleIcon aria-hidden="true" />
                            <div class="menu-title" :class="getClassForSelectedMenu(RoutePageNameEnum.Schedule)">{{ localize('Расписание') }}</div>
                        </router-link>
                    </div>

                    <!-- Проверяем есть ли у пользователя роль, если есть, то отображаем выпадающее меню, иначе просто сслыку на университеты  -->
                    <div
                        v-if="!isDisabledUniversityMenu"
                        @mouseover="isUniversityMenuOver = true"
                        @mouseout="isUniversityMenuOver = false"
                        :class="{ active: isPageUniversity }"
                        class="menu-item with-sub-menu"
                    >
                        <div
                            tabindex="0"
                            role="menuitem"
                            @click="onClickAtParent($event, () => isUniversityMenuOver = true)"
                            :class="getClassForSelectedMenu(RoutePageNameEnum.Universities)"
                            class="menu-item__link"
                        >
                            <div class="flex items-center justify-center">
                                <BuildingIcon aria-hidden="true" />
                                <div class="menu-title" :class="isPageUniversity? 'selected-menu':''">{{ localize('Организация') }}</div>
                            </div>
                        </div>
                        <!-- Пользователям без роли не отображаем подменю. Студентам - урезанное меню. Остальным- полное меню -->
                        <q-menu
                            v-model="isVisibleUniversityMenu"
                            class="sub-menu sub-menu__transition"
                            auto-close
                            anchor="top right"
                        >
                            <left-menu-university
                                @mouseover.native="isUniversityMenuListOver = true"
                                @mouseout.native="isUniversityMenuListOver = false"
                                :universities="universityUser"
                            />
                            <q-list
                                @mouseover.native="isUniversityMenuListOver = true"
                                @mouseout.native="isUniversityMenuListOver = false"
                                @click="isUniversityMenuListOver = false"
                                class="left-menu-component__sub-menu__list"
                            >
                                <q-item
                                    v-for="(item, index) in getUniversityMenuItems()"
                                    class="left-menu-component__sub-menu__list__item"
                                    :key="'index' + index"
                                    clickable
                                    :active="getRoutePageName(item.page) === currentPage"
                                    :to="getPageUrl(item.page)"
                                    role="menuitem"
                                >
                                    <q-item-section>{{ item.title }}</q-item-section>
                                </q-item>
                            </q-list>
                        </q-menu>

                        <div v-if="isVisibleUniversityMenu" class="sub-menu-mobile">
                            <left-menu-university :universities="universityUser" />
                            <router-link
                                v-for="(item, index) in getUniversityMenuItems()"
                                :key="'index' + index"
                                class="menu-item"
                                :class="{ active: getRoutePageName(item.page) === currentPage }"
                                :to="getPageUrl(item.page)"
                            >
                                <div class="menu-title">{{ item.title }}</div>
                            </router-link>
                        </div>
                    </div>
                    <!-- Пункт университеты для пользователей без ролей не отображаем -->

                    <div
                        v-if="showMaterialRoles"
                        class="menu-item with-sub-menu"
                        @mouseover="isMaterialMenuOver = true"
                        @mouseout="isMaterialMenuOver = false"
                        :class="{ active: isMaterials }"
                    >
                        <div class="flex items-center justify-center menu-item__link" @click="onClickAtParent">
                            <OpenBookIcon aria-hidden="true" />
                            <div :class="isMaterials? 'selected-menu':''" class="menu-title">{{ localize('Материалы') }}</div>
                        </div>

                        <q-menu
                            v-model="isVisibleMaterialMenu"
                            class="sub-menu sub-menu__transition"
                            auto-close
                            anchor="top right"
                        >
                            <q-list
                                class="left-menu-component__sub-menu__list"
                                @mouseover.native="isMaterialMenuListOver = true"
                                @mouseout.native="isMaterialMenuListOver = false"
                            >
                                <q-item
                                    :class="{ active: currentUserToLibraryPath === currentFullPath }"
                                    class="left-menu-component__sub-menu__list__item cursor-pointer"
                                    :to="getPageUrl(RoutePageNameEnum.Library)"
                                >
                                    <q-item-section>{{ localize('Библиотека') }}</q-item-section>
                                </q-item>
                                <q-item
                                    :class="{
                                        active: getRoutePageName(RoutePageNameEnum.ActivityPlan) === currentPage,
                                    }"
                                    class="left-menu-component__sub-menu__list__item cursor-pointer"
                                    :to="getPageUrl(RoutePageNameEnum.ActivityPlan)"
                                >
                                    <q-item-section>{{ localize('Шаблоны') }}</q-item-section>
                                </q-item>
                                <q-item
                                    :class="{ active: getRoutePageName(RoutePageNameEnum.Stock) === currentPage }"
                                    class="left-menu-component__sub-menu__list__item cursor-pointer"
                                    :to="getPageUrl(RoutePageNameEnum.Stock)"
                                >
                                    <q-item-section>{{ localize('Биржа') }}</q-item-section>
                                </q-item>
                                <q-item
                                    :class="{
                                        active: getRoutePageName(RoutePageNameEnum.KnowledgeMapInfo) === currentPage,
                                    }"
                                    class="left-menu-component__sub-menu__list__item cursor-pointer"
                                    :to="getPageUrl(RoutePageNameEnum.KnowledgeMapInfo)"
                                >
                                    <q-item-section>{{ localize('Карты знаний') }}</q-item-section>
                                </q-item>
                            </q-list>
                        </q-menu>

                        <div v-if="isVisibleMaterialMenu" class="sub-menu-mobile">
                            <router-link
                                v-for="(item, index) in materialMenuItems"
                                :key="'index' + index"
                                class="menu-item"
                                :class="{ active: item.page.toString() === currentPage }"
                                :to="getPageUrl(item.page)"
                            >
                                <div class="menu-title">{{ item.title }}</div>
                            </router-link>
                        </div>
                    </div>
                    <div
                        v-else
                        class="menu-item"
                        :class="{ active: currentUserToLibraryPath === currentFullPath }"
                    >
                        <router-link :to="getPageUrl(RoutePageNameEnum.Library)">
                            <OpenBookIcon aria-hidden="true" />
                            <div class="menu-title">{{ localize('Библиотека') }}</div>
                        </router-link>
                    </div>

                    <div
                        :class="{ active: getRoutePageName(RoutePageNameEnum.Chat) === currentPage }"
                        class="menu-item"
                    >
                        <router-link class="menu-item__link" :to="getPageUrl(RoutePageNameEnum.Chat)">
                            <ChatIcon aria-hidden="true" />
                            <div class="menu-title" :class="getClassForSelectedMenu(RoutePageNameEnum.Chat)">{{ localize('Чат') }}</div>
                            <div class="notify" v-if="countUnreadedMessages">{{ getLimitedUnreadCountEvents(countUnreadedMessages) }}</div>
                        </router-link>
                    </div>

                    <div
                        v-if="callNotifications.length"
                        @click="showCalls"
                        class="menu-item only-mobile"
                    >
                        <AcceptCallIcon name="AcceptCallIcon" class="link q-ma-none" />
                        <div class="menu-title">{{ localize('Текущие звонки') }}</div>
                        <div class="notify">{{ callNotifications.length }}</div>
                    </div>

                    <div class="menu-item" @click="showSearchBlock" role="button" tabindex="0">
                        <div class="menu-item__link">
                            <SearchIcon aria-hidden="true" />
                            <div class="menu-title">{{ localize('Поиск') }}</div>
                        </div>
                    </div>

                    <div
                        v-if="isSuperAdmin"
                        :class="{ active: isSettings }"
                        @mouseover="isSettingsMenuOver = true"
                        @mouseout="isSettingsMenuOver = false"
                        class="menu-item with-sub-menu"
                    >
                        <div class="flex items-center justify-center menu-item__link" @click="onClickAtParent">
                            <SettingsIcon />
                            <div class="menu-title ellipsis" :class="isSettings? 'selected-menu':''">{{ localize('Администрирование') }}</div>
                        </div>

                        <q-menu
                            v-model="isVisibleSettingsMenu"
                            class="sub-menu sub-menu__transition"
                            auto-close
                            anchor="top right"
                        >
                            <q-list
                                @mouseover.native="isSettingsMenuListOver = true"
                                @mouseout.native="isSettingsMenuListOver = false"
                                class="left-menu-component__sub-menu__list"
                            >

                                <q-item
                                    :class="{ active: getRoutePageName(RoutePageNameEnum.Locations) === currentPage }"
                                    class="cursor-pointer"
                                    :to="getPageUrl(RoutePageNameEnum.Locations)"
                                >
                                    <q-item-section>{{ localize('Города и страны') }}</q-item-section>
                                </q-item>

                                <q-item
                                    :class="{ active: getRoutePageName(RoutePageNameEnum.Equipments) === currentPage }"
                                    class="cursor-pointer"
                                    :to="getPageUrl(RoutePageNameEnum.Equipments)"
                                >
                                    <q-item-section>{{ localize('Оборудование') }}</q-item-section>
                                </q-item>

                                <q-item
                                    class="cursor-pointer"
                                    :class="{ active: getRoutePageName(RoutePageNameEnum.Specialties) === currentPage }"
                                    :to="getPageUrl(RoutePageNameEnum.Specialties)"
                                >
                                    <q-item-section>
                                        {{ localize('Направления подготовки') }}
                                    </q-item-section>
                                </q-item>
                                <q-item
                                    :class="{
                                        active: getRoutePageName(RoutePageNameEnum.AssignedRoles) === currentPage,
                                    }"
                                    class="cursor-pointer"
                                    :to="getPageUrl(RoutePageNameEnum.AssignedRoles)"
                                >
                                    <q-item-section>{{ localize('Назначенные роли') }}</q-item-section>
                                </q-item>
                                <q-item
                                    :class="{ active: getRoutePageName(RoutePageNameEnum.EditTranslations) === currentPage }"
                                    class="cursor-pointer"
                                    :to="getPageUrl(RoutePageNameEnum.EditTranslations)">
                                    <q-item-section>{{ localize('Редактор переводов') }}</q-item-section>
                                </q-item>
                                <q-item
                                    :class="{ active: getRoutePageName(RoutePageNameEnum.EditMiltiLanguageTranslations) === currentPage }"
                                    class="cursor-pointer"
                                    :to="getPageUrl(RoutePageNameEnum.EditMiltiLanguageTranslations)">
                                    <q-item-section>{{ localize('Редактор переводов v2') }}</q-item-section>
                                </q-item>
                                <q-item
                                    :class="{ active: getRoutePageName(RoutePageNameEnum.MigrationMethods) === currentPage }"
                                    class="cursor-pointer"
                                    :to="getPageUrl(RoutePageNameEnum.MigrationMethods)">
                                    <q-item-section>{{ localize('Миграции') }}</q-item-section>
                                </q-item>
                                <q-item
                                    :class="{
                                        active: getRoutePageName(RoutePageNameEnum.SourceVersion) === currentPage,
                                    }"
                                    class="cursor-pointer"
                                    :to="getPageUrl(RoutePageNameEnum.SourceVersion)"
                                >
                                    <q-item-section>{{ localize('Версия приложения') }}</q-item-section>
                                </q-item>
                                <q-item
                                    class="cursor-pointer"
                                    :href="changeCanaryLink"
                                >
                                    <q-item-section>{{ localize(isCanary ? 'Стабильная версия' : 'Canary версия') }}</q-item-section>
                                </q-item>
                            </q-list>
                        </q-menu>

                        <div v-if="isSuperAdmin && isVisibleSettingsMenu" class="sub-menu-mobile">
                            <router-link
                                class="menu-item"
                                :class="{ active: getRoutePageName(RoutePageNameEnum.Locations) === currentPage }"
                                :to="getPageUrl(RoutePageNameEnum.Locations)"
                            >
                                <div class="menu-title">{{ localize('Города и страны') }}</div>
                            </router-link>
                            <router-link
                                class="menu-item"
                                :class="{ active: getRoutePageName(RoutePageNameEnum.Equipments) === currentPage }"
                                :to="getPageUrl(RoutePageNameEnum.Equipments)"
                            >
                                <div class="menu-title">{{ localize('Оборудование') }}</div>
                            </router-link>
                            <router-link
                                class="menu-item"
                                :class="{ active: getRoutePageName(RoutePageNameEnum.Specialties) === currentPage }"
                                :to="getPageUrl(RoutePageNameEnum.Specialties)"
                            >
                                <div class="menu-title">{{ localize('Направления подготовки') }}</div>
                            </router-link>
                            <router-link
                                class="menu-item"
                                :class="{ active: getRoutePageName(RoutePageNameEnum.AssignedRoles) === currentPage}"
                                :to="getPageUrl(RoutePageNameEnum.AssignedRoles)"
                            >
                                <div class="menu-title">{{ localize('Назначенные роли') }}</div>
                            </router-link>
                            <router-link
                                class="menu-item"
                                :class="{ active: getRoutePageName(RoutePageNameEnum.EditTranslations) === currentPage }"
                                :to="getPageUrl(RoutePageNameEnum.EditTranslations)"
                            >
                                <div class="menu-title">{{ localize('Редактор переводов') }}</div>
                            </router-link>
                            <router-link
                                class="menu-item"
                                :class="{active: getRoutePageName(RoutePageNameEnum.SourceVersion) === currentPage}"
                                :to="getPageUrl(RoutePageNameEnum.SourceVersion)"
                            >
                                <div class="menu-title">{{ localize('Версия приложения') }}</div>
                            </router-link>
                            <a
                                class="menu-item"
                                :href="changeCanaryLink"
                            >
                                <div class="menu-title">{{ localize(isCanary ? 'Стабильная версия' : 'Canary версия') }}</div>
                            </a>
                        </div>
                    </div>

                    <div
                        :class="{ active: currentUserPage === currentFullPath }"
                        @mouseover="isProfileMenuOver = true"
                        @mouseout="isProfileMenuOver = false"
                        class="menu-item with-sub-menu"
                    >
                        <div
                            v-if="accountInfo"
                            tabindex="0"
                            role="menuitem"
                            @click="onClickAtParent($event, () => isProfileMenuOver = true)"
                            class="flex items-center justify-center menu-item__link"
                        >
                            <div class="q-mx-xs position-relative" aria-hidden="true">
                                <q-avatar size="24px">
                                    <div
                                        class="left-menu-component__user_avatar"
                                        v-if="accountInfo.photoPreviewPath"
                                        :style="{ 'background-image': `url(${accountInfo.photoPreviewPath})` }"
                                    ></div>
                                    <custom-avatar
                                        :color="CustomAvatarColors.User"
                                        :text="accountInfo.lastName[0] + accountInfo.firstName[0]"
                                    />
                                </q-avatar>
                                <div class="notify" v-if="countUnreadedNotification !== 0">
                                    {{ getLimitedUnreadCountEvents(countUnreadedNotification) }}
                                </div>
                            </div>
                            <div class="menu-title">
                                <template v-if="isActiveLang(LocaleName.EN)">
                                    {{ accountInfo.firstName + ' ' + accountInfo.lastName }}
                                </template>
                                <template v-else>
                                    {{ accountInfo.lastName + ' ' + accountInfo.firstName }}
                                </template>
                            </div>
                        </div>
                        <q-menu
                            v-model="isVisibleProfileMenu"
                            class="sub-menu sub-menu__transition"
                            auto-close
                            anchor="top right"
                        >
                            <q-list
                                @mouseover.native="isProfileMenuListOver = true"
                                @mouseout.native="isProfileMenuListOver = false"
                                class="left-menu-component__sub-menu__list"
                            >
                                <q-item
                                    :class="{ active: currentUserPage === currentFullPath }"
                                    class="left-menu-component__sub-menu__list__item cursor-pointer"
                                    :to="getPageUrl(RoutePageNameEnum.UserInfo)"
                                >
                                    <q-item-section>
                                        <div class="row items-center">
                                            {{ localize('Профиль') }}
                                            <div
                                                v-if="countUnreadedNotification"
                                                class="mini-circle-notify q-ml-sm"
                                            ></div>
                                        </div>
                                    </q-item-section>
                                </q-item>
                                <q-item
                                    @click="logOut"
                                    :to="{ name: loginRouteName }"
                                    clickable
                                    class="left-menu-component__sub-menu__list__item cursor-pointer text-error"
                                >
                                    <q-item-section>{{ localize('Выйти') }}</q-item-section>
                                </q-item>
                                <q-item class="language-block__wrap">
                                    <div class="language-block flex justify-between items-center">
                                        <a
                                            :href="'/' + LocaleName.RU + $route.fullPath"
                                            @click.prevent="setLocale(LocaleName.RU)"
                                            class="link"
                                            :class="{ active: isActiveLang(LocaleName.RU) }"
                                        >
                                            РУС
                                        </a>
                                        <div class="separator"></div>
                                        <a
                                            :href="'/' + LocaleName.EN + $route.fullPath"
                                            @click.prevent="setLocale(LocaleName.EN)"
                                            class="link"
                                            :class="{ active: isActiveLang(LocaleName.EN) }"
                                        >
                                            ENG
                                        </a>
                                    </div>
                                </q-item>
                            </q-list>
                        </q-menu>

                        <div v-if="isVisibleProfileMenu" class="sub-menu-mobile">
                            <div
                                :class="{ active: currentUserPage === currentFullPath }"
                                class="menu-item"
                            >
                                <router-link :to="getPageUrl(RoutePageNameEnum.UserInfo)">
                                    <div class="menu-title">
                                        {{ localize('Профиль') }}
                                        <div v-if="countUnreadedNotification" class="mini-circle-notify q-ml-sm"></div>
                                    </div>
                                </router-link>
                            </div>
                            <router-link :to="{ name: loginRouteName }" @click="logOut" class="menu-item text-error">
                                <div class="menu-title">{{ localize('Выйти') }}</div>
                            </router-link>
                            <div class="menu-item">
                                <div class="language-block flex justify-between items-center">
                                    <div
                                        @click="setLocale(LocaleName.RU)"
                                        class="link"
                                        :class="{ active: isActiveLang(LocaleName.RU) }"
                                    >
                                        РУС
                                    </div>
                                    <div class="separator"></div>
                                    <div
                                        @click="setLocale(LocaleName.EN)"
                                        class="link"
                                        :class="{ active: isActiveLang(LocaleName.EN) }"
                                    >
                                        ENG
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <q-separator v-if="divisions.length" class="q-mt-md q-mx-sm" />

                    <div class="menu-item" v-for="item in divisions" :key="item.id">
                        <unpin-context-menu
                            ref="unpinMenuRef"
                            @unpin="unpinDivision(item.id)"
                            @open-in-new-tab="openDivisionPage(item.id, 'tab')"
                            @open-in-new-window="openDivisionPage(item.id)"
                        />
                        <pinned-page
                            :page="item"
                            :avatar-color="CustomAvatarColors.Division"
                            :avatar-text="localize('П')"
                            :route-name="getDivisionRouteName"
                        />
                    </div>

                    <q-separator v-if="programs.length" class="q-mt-md q-mx-sm" />

                    <div class="menu-item" v-for="item in programs" :key="item.id">
                        <unpin-context-menu
                            ref="unpinMenuRef"
                            @unpin="unpinProgram(item.id)"
                            @open-in-new-tab="openProgramPage(item.id, 'tab')"
                            @open-in-new-window="openProgramPage(item.id)"
                            @click.native="closeMenu"
                        />
                        <pinned-page
                            :page="item"
                            :avatar-color="CustomAvatarColors.Program"
                            :avatar-text="localize('Пр')"
                            :route-name="getProgramRouteName"
                            @click.native="closeMenu"
                        />
                    </div>

                    <q-separator v-if="cohorts.length" class="q-mt-md q-mx-sm" />

                    <div class="menu-item" v-for="item in cohorts" :key="item.id">
                        <unpin-context-menu
                            ref="unpinMenuRef"
                            @unpin="unpinCohort(item.id)"
                            @open-in-new-tab="openCohortPage(item.id, 'tab')"
                            @open-in-new-window="openCohortPage(item.id)"
                            @click.native="closeMenu"
                        />
                        <pinned-page
                            :page="item"
                            :avatar-color="CustomAvatarColors.Cohort"
                            :avatar-text="localize('По')"
                            :route-name="getCohortRouteName"
                            @click.native="closeMenu"
                        />
                    </div>

                    <q-separator v-if="disciplines.length" class="q-mt-md q-mx-sm" />

                    <div class="menu-item" :key="item.id" v-for="item in disciplines">
                        <unpin-context-menu
                            ref="unpinMenuRef"
                            @unpin="unpinDiscipline(item.id)"
                            @open-in-new-tab="openDisciplinePage(item.id, 'tab')"
                            @open-in-new-window="openDisciplinePage(item.id)"
                            @click.native="closeMenu"
                        />
                        <pinned-discipline-page-block @click.native="closeMenu">
                            <router-link :to="getDisciplinePageUrl(item.id)" @click.native="closeMenu">
                                <q-avatar size="24px" class="rounded-borders" aria-hidden="true">
                                    <img v-if="item.photoUrl" :src="item.photoUrl" alt="" />
                                    <custom-avatar
                                        v-else
                                        :text="localize('Д')"
                                        :color="CustomAvatarColors.Discipline"
                                    />
                                </q-avatar>
                                <div class="menu-title" >
                                    <span class="menu-title__name">
                                        {{ item.name }}
                                    </span>
                                </div>
                                <CountUnreadedMessages :discipline-id="item.id" class="notify" />
                            </router-link>
                        </pinned-discipline-page-block>
                    </div>
                </q-scroll-area>
            </nav>

            <div
                class="menu-item with-sub-menu support-menu"
                @mouseover="isSupportMenuOver = true"
                @mouseout="isSupportMenuOver = false"
            >
                <div
                    tabindex="0"
                    role="menuitem"
                    @click="onClickAtParent($event, () => isSupportMenuOver = true)"
                    class="help-block-sidebar flex items-center justify-center no-wrap"
                >
                    <q-icon name="support" class="life-ring-icon" />
                    <div class="menu-title">{{ localize('Помощь') }}</div>
                </div>

                <q-menu
                    v-model="isVisibleSupportMenu"
                    class="sub-menu support-menu sub-menu__transition"
                >
                    <q-list
                        @mouseover.native="isSupportMenuListOver = true"
                        @mouseout.native="isSupportMenuListOver = false"
                    >
                        <q-item class="small-item cursor-pointer q-pa-none">
                            <q-item-section>
                                <a
                                    :href="helpCenterLink"
                                    class="q-px-md q-py-sm"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    {{ localize('Справочный центр') }}
                                </a>
                            </q-item-section>
                        </q-item>
                        <q-item
                            v-if="!isOnlyStudent"
                            class="small-item cursor-pointer q-pa-none">
                            <q-item-section>
                                <a
                                    href="https://informa.gitbook.io/odin/novosti/novosti-odin"
                                    class="q-px-md q-py-sm"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    {{ localize('Новости') }}
                                </a>
                            </q-item-section>
                        </q-item>
                        <q-item
                            :class="{ active: getRoutePageName(RoutePageNameEnum.UserInfo) === currentPage }"
                            class="small-item cursor-pointer">
                            <q-item-section>
                                <a
                                    :href="supportFormLink"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    {{ localize('Задать вопрос') }}
                                </a>
                            </q-item-section>
                        </q-item>
                        <q-item class="cursor-pointer">
                            <q-item-section class="small-text-item">
                                <div class="row">
                                    <a :href="getAgreementLink()">{{ localize('Соглашение') }}</a>
                                    <span>&nbsp;{{ localize('и') }}&nbsp;</span>
                                    <a :href="getConfidentialityLink()">{{ localize('Конфиденциальность') }}</a>
                                </div>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-menu>

                <div v-if="isVisibleSupportMenu" class="sub-menu-mobile">
                    <div class="menu-item">
                        <div class="menu-title">
                            <a
                                :href="helpCenterLink"
                                class="menu-title-link"
                                rel="noopener noreferrer"
                                target="_blank">
                                {{ localize('Справочный центр') }}
                            </a>
                        </div>
                    </div>
                    <div class="menu-item">
                        <div class="menu-title">
                            <a
                                :href="supportFormLink"
                                rel="noopener noreferrer"
                                target="_blank">
                                {{ localize('Задать вопрос') }}
                            </a>
                        </div>
                    </div>
                    <div v-if="!isOnlyStudent" class="menu-item">
                        <div class="menu-title">
                            <a
                                href="https://informa.gitbook.io/odin/novosti/novosti-odin"
                                class="menu-title-link"
                                rel="noopener noreferrer"
                                target="_blank">
                                {{ localize('Новости') }}
                            </a>
                        </div>
                    </div>
                    <div class="menu-item" @click="toAgreement">
                        <div class="menu-title">{{ localize('Соглашение') }}</div>
                    </div>
                    <div class="menu-item" @click="toConfidentiality">
                        <div class="menu-title">{{ localize('Конфиденциальность') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <q-resize-observer @resize="setSize" :debounce="500" />
    </q-drawer>
</template>

<script lang="ts">
    import {
        defineComponent,
        Ref,
        ref,
        watch,
        computed,
        onMounted,
        nextTick,
        getCurrentInstance,
    } from 'vue';
    import {
        AccountCurrentInfoResponseModel,
        CohortClient,
        CurrentUserInfoResponseModel,
        DisciplineClient,
        DivisionClient,
        EducationalProgramClient,
        EntityBaseInfoDto,
        LanguagesEnum,
        Result,
        ResultOf,
        RoleEnum,
        RoutePageNameEnum,
        UniversitySelectModel,
        UserClient,
        UserSetLanguageRequest,
    } from 'src/api/ApiClient';
    import { RouteLocation, useRoute, useRouter } from 'vue-router';
    import { LocaleName } from 'src/types/LocaleName';
    import LocalizationService, { localize } from 'src/services/LocalizationService';
    import { debounce, QDrawer, QMenu, QScrollArea } from 'quasar';
    import { Common } from 'src/helpers/Common';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import CountUnreadedMessages from 'components/CountUnreadedMessages/CountUnreadedMessages.vue';
    import LeftMenuUniversity from 'layouts/Main/components/LeftMenuUniversity.vue';
    import UnpinContextMenu from 'layouts/Main/components/UnpinContextMenu.vue';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import PinnedPage from 'layouts/Main/components/PinnedPageBlock.vue';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import PinnedDisciplinePageBlock from 'layouts/Main/components/PinnedDisciplinePageBlock.vue';
    import { useAccountStore } from 'src/store/module-account';
    import { useAuthorizationStore } from 'src/store/module-authorization';
    import { useNotificationsStore } from 'src/store/module-notifications';
    import { useMainLayoutStore } from 'src/store/module-main-layout';
    import { useChatStore } from 'src/store/module-chat';

    export default defineComponent({
        name: 'LeftMenu',
        components: {
            PinnedDisciplinePageBlock,
            LeftMenuUniversity,
            CountUnreadedMessages,
            PinnedPage,
            UnpinContextMenu,
        },
        /* eslint-disable-next-line max-lines-per-function  */
        setup() {
            const app = getCurrentInstance();
            const $router = useRouter();
            const $route = useRoute();
            const chatStore = useChatStore();
            const accountStore = useAccountStore();
            const notificationsStore = useNotificationsStore();
            const mainLayoutStore = useMainLayoutStore();

            const rootElRef = ref<QDrawer | null>(null);
            const scrollAreaRef = ref<QScrollArea | null>(null);
            const unpinMenuRef = ref<InstanceType<typeof UnpinContextMenu>[] | null>(null);
            const isNewYear = Common.isNewYearTime();

            // Видимость самого левого меню
            const isVisible: Ref<boolean> = ref(true);

            //region Флаги для показа/скрытия меню
            const isVisibleUniversityMenu: Ref<boolean>  = ref(false);
            const isUniversityMenuOver: Ref<boolean>  = ref(false);
            const isUniversityMenuListOver: Ref<boolean>  = ref(false);

            const isVisibleProfileMenu: Ref<boolean>  = ref(false);
            const isProfileMenuOver: Ref<boolean>  = ref(false);
            const isProfileMenuListOver: Ref<boolean>  = ref(false);

            const isVisibleMaterialMenu: Ref<boolean>  = ref(false);
            const isMaterialMenuOver: Ref<boolean>  = ref(false);
            const isMaterialMenuListOver: Ref<boolean>  = ref(false);

            const isVisibleSettingsMenu: Ref<boolean>  = ref(false);
            const isSettingsMenuOver: Ref<boolean>  = ref(false);
            const isSettingsMenuListOver: Ref<boolean>  = ref(false);

            const isVisibleSupportMenu: Ref<boolean>  = ref(false);
            const isSupportMenuOver: Ref<boolean>  = ref(false);
            const isSupportMenuListOver: Ref<boolean>  = ref(false);
            //endregion

            // Мобильное отображение начинается с 960px
            const mobileWidthMax: number = 960;

            // скрываем блок с тенью внизу или нет
            const isViewScroll: Ref<boolean> = ref(false);

            // Информация об университете для
            // использования в выпадающем списке
            const universityUser: Ref<UniversitySelectModel[]> = ref([]);

            const isCanary: boolean = Common.isCanary();
            const changeCanaryLink: string = `/spa/tech/reload?canary=${!isCanary}`;

            // Для пользователей без роли, с ролью демо или картограф - не показываем пункт меню "Университет"
            const isDisabledUniversityMenu = computed((): boolean => {
                return accountStore.getIsDisabledUniversityMenu;
            });

            // Ссылка на форму обращения в тех.поддержку
            const supportFormLink = computed((): string => {
                const baseLink = 'https://forms.yandex.ru/cloud/659fee4673cee70f5b63b79a';
                if (!accountStore.getAccountInfo) {
                    return baseLink;
                }

                return `${baseLink}?answer_non_profile_email_41288802=${accountStore.getAccountInfo.email}&answer_url_41279152=https://www.odin.study/ru/User/Info/${accountStore.getAccountInfo.id}`;
            });

            // Пункты меню, недоступные для студента
            const universityMenuItemsAll: { title: string; page: number, isVisible: boolean }[] = [
                {
                    title: localize('Организации'),
                    page: RoutePageNameEnum.Universities,
                    isVisible: accountStore.getAccountInfo?.isVisibleUniversitiesMenuPage ?? false
                },
                {
                    title: localize('Подразделения'),
                    page: RoutePageNameEnum.Divisions,
                    isVisible: true
                },
                {
                    title: localize('Программы'),
                    page: RoutePageNameEnum.Programs,
                    isVisible: true
                },
                {
                    title: localize('Потоки'),
                    page: RoutePageNameEnum.Cohorts,
                    isVisible: true
                },
                {
                    title: localize('Дисциплины'),
                    page: RoutePageNameEnum.Disciplines,
                    isVisible: true
                },
                {
                    title: localize('Активности'),
                    page: RoutePageNameEnum.Activities,
                    isVisible: true
                },
                {
                    title: localize('Люди'),
                    page: RoutePageNameEnum.UsersTunableList,
                    isVisible: true
                },
            ];

            // Пункты меню доступные для студента
            const universityMenuItemsForStudent: { title: string; page: number, isVisible: boolean }[] = [
                {
                    title: localize('Организации'),
                    page: RoutePageNameEnum.Universities,
                    isVisible: accountStore.getAccountInfo?.isVisibleUniversitiesMenuPage ?? false
                },
                {
                    title: localize('Подразделения'),
                    page: RoutePageNameEnum.Divisions,
                    isVisible: true
                },
                {
                    title: localize('Дисциплины'),
                    page: RoutePageNameEnum.Disciplines,
                    isVisible: true
                },
                {
                    title: localize('Активности'),
                    page: RoutePageNameEnum.Activities,
                    isVisible: true
                },
            ];

            // Вложенное меню библиотек
            const materialMenuItems: { title: string; page: number }[] = [
                {
                    title: localize('Библиотека'),
                    page: RoutePageNameEnum.Library,
                },
                {
                    title: localize('Шаблоны'),
                    page: RoutePageNameEnum.ActivityPlan,
                },
                {
                    title: localize('Биржа'),
                    page: RoutePageNameEnum.Stock,
                },
                {
                    title: localize('Карты знаний'),
                    page: RoutePageNameEnum.KnowledgeMapInfo,
                },
            ];

            // Ширина меню
            const width: Ref<number> = ref(0);

            // Текущая выбранная страница
            const currentPage: Ref<string> = ref('');

            // ПОлный путь текущей страницы
            const currentFullPath: Ref<string> = ref('');

            // Показывать tooltip или нет
            const isViewTooltip: Ref<boolean> = ref(true);

            const isVisibleLeftMenu = computed((): boolean => {
                return mainLayoutStore.isVisibleLeftMenu;
            });

            const getDivisionRouteName = Common.getRouteName(RoutePageNameEnum.DivisionInfo);

            const getProgramRouteName = Common.getRouteName(RoutePageNameEnum.EducationalProgramInfo);

            const getCohortRouteName = Common.getRouteName(RoutePageNameEnum.CohortInfo);

            const loginRouteName = Common.getRouteName(RoutePageNameEnum.Login);

            // Текущий пользователь имеет роль "Суперадминистратор"
            const isSuperAdmin = Common.isHaveRole(RoleEnum.SuperAdministratorRole);

            // Текущий пользователь имеет только роль "Студент"
            const isOnlyStudent = Common.isOnlyStudent();

            // Расписание показываем для преподавателя или студента
            const isShowSchedule = Common.isHaveRole(RoleEnum.TeacherRole)
                || Common.isHaveRole(RoleEnum.StudentRole)
                || Common.isHaveRole(RoleEnum.GroupCuratorRole);

            // Кто может видеть меню с материалами
            const showMaterialRoles = Common.isHaveRole(RoleEnum.SuperAdministratorRole) ||
                Common.isHaveRole(RoleEnum.AuditorRole) ||
                Common.isHaveRole(RoleEnum.CartographerRole) ||
                Common.isHaveRole(RoleEnum.UniversityAdministratorRole) ||
                Common.isHaveRole(RoleEnum.DivisionAdministratorRole) ||
                Common.isHaveRole(RoleEnum.EducationalProgramAdministratorRole) ||
                Common.isHaveRole(RoleEnum.TeacherRole) ||
                Common.isHaveRole(RoleEnum.UniversityContentManagerRole) ||
                Common.isHaveRole(RoleEnum.CohortAdministratorRole);

            //region Вотчеры флаг для показа высплывающих меню
            watch(isVisibleLeftMenu, (val: boolean) => {
                isVisible.value = val;
            });

            watch(isUniversityMenuOver, () => {
                showUniversityMenuDebounce();
            });

            watch(isUniversityMenuListOver, () => {
                showUniversityMenuDebounce();
            });

            watch(isMaterialMenuOver, () => {
                showMaterialMenuDebounce();
            });

            watch(isSettingsMenuOver, () => {
                showSettingsMenuDebounce();
            });

            watch(isProfileMenuOver, () => {
                showProfileMenuDebounce();
            });

            watch(isProfileMenuListOver, () => {
                showProfileMenuDebounce();
            });

            watch(isSupportMenuOver, () => {
                showSupportMenuDebounce();
            });

            watch(isSupportMenuListOver, () => {
                showSupportMenuDebounce();
            });
            //endregion

            watch($route, (route: RouteLocation) => {
                currentPage.value = route.name ? route.name.toString() : '';
                currentFullPath.value = route.fullPath;
            });

            // Список подразделений для отображения
            const divisions = computed((): EntityBaseInfoDto[] => {
                return mainLayoutStore.getPinnedDivisions;
            });

            // Список программ для отображения
            const programs = computed((): EntityBaseInfoDto[] => {
                return mainLayoutStore.getPinnedPrograms;
            });

            // Список потоков для отображения
            const cohorts = computed((): EntityBaseInfoDto[]  => {
                return mainLayoutStore.getPinnedCohorts;
            });

            // Список дисциплин для отображения
            const disciplines = computed((): EntityBaseInfoDto[]  => {
                return mainLayoutStore.getPinnedDisciplines;
            });

            //region Определения текушей страницы
            const isPageUniversity = computed((): boolean  => {
                return (
                    getRoutePageName(RoutePageNameEnum.Universities) === currentPage.value ||
                    getRoutePageName(RoutePageNameEnum.Divisions) === currentPage.value ||
                    getRoutePageName(RoutePageNameEnum.Programs) === currentPage.value ||
                    getRoutePageName(RoutePageNameEnum.Cohorts) === currentPage.value ||
                    getRoutePageName(RoutePageNameEnum.Disciplines) === currentPage.value ||
                    getRoutePageName(RoutePageNameEnum.Activities) === currentPage.value ||
                    getRoutePageName(RoutePageNameEnum.UsersTunableList) === currentPage.value
                );
            });

            const isMaterials = computed((): boolean  => {
                return (
                    getRoutePageName(RoutePageNameEnum.Library) === currentPage.value ||
                    getRoutePageName(RoutePageNameEnum.ActivityPlan) === currentPage.value ||
                    getRoutePageName(RoutePageNameEnum.DisciplinePlan) === currentPage.value ||
                    getRoutePageName(RoutePageNameEnum.KnowledgeMapInfo) === currentPage.value ||
                    getRoutePageName(RoutePageNameEnum.Stock) === currentPage.value ||
                    getRoutePageName(RoutePageNameEnum.StockEdit) === currentPage.value ||
                    getRoutePageName(RoutePageNameEnum.StockInfo) === currentPage.value
                );
            });

            const isSettings = computed((): boolean  => {
                return (
                    isCurrentPage(RoutePageNameEnum.Locations) ||
                    isCurrentPage(RoutePageNameEnum.Equipments) ||
                    isCurrentPage(RoutePageNameEnum.Specialties) ||
                    isCurrentPage(RoutePageNameEnum.AssignedRoles) ||
                    isCurrentPage(RoutePageNameEnum.SourceVersion) ||
                    isCurrentPage(RoutePageNameEnum.EditTranslations) ||
                    isCurrentPage(RoutePageNameEnum.EditMiltiLanguageTranslations)
                );
            });
            //endregion

            // Информация о текущем пользователе
            const accountInfo = computed((): AccountCurrentInfoResponseModel | null  => {
                return accountStore.getAccountInfo;
            });

            // Непрочитанные сообщения по всем чата
            const countUnreadedMessages = computed((): number => {
                return chatStore.getAllUreadedMessagesCount;
            });

            const supportEmail = computed((): string => {
                return accountStore.getSupportEmail;
            });

            // Непрочитанные оповещения
            const countUnreadedNotification = computed((): number  => {
                return notificationsStore.getCountNotifications;
            });

            // пункт меню "Текущие звонки" показываем если они есть
            const callNotifications = computed(() => {
                return notificationsStore.getCallNotifications;
            });

            // Получить ссылку на 'Справочный центр' в зависимости от роли пользователя
            // https://fsjunior.atlassian.net/browse/ODIN-6153
            const helpCenterLink = computed((): string => {
                return accountStore.getAccountInfo?.roles.length === 1 && Common.isHaveRole(RoleEnum.StudentRole)
                    ? 'https://informa.gitbook.io/odin_students'
                    : 'https://informa.gitbook.io/odin';
            });

            const currentUserToLibraryPath = computed((): string  => {
                const path = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.Library),
                    params: { path: [accountStore.getAccountInfo?.libraryId.toString() ?? ''] },
                });
                return path.fullPath;
            });

            // URL на страницу текущего пользователя
            const currentUserPage = computed((): string  => {
                if (accountStore.getAccountInfo) {
                    const path = $router.resolve({
                        name: Common.getRouteName(RoutePageNameEnum.UserInfo),
                        params: { id: accountStore.getAccountInfo.id },
                    });
                    return path.fullPath;
                } else {
                    return '';
                }
            });

            //region Обработчики показа/скрытия встыплывающий меню
            const showUniversityMenuDebounce: () => void = debounce(() => {
                checkUniversityMenu();
            }, 300);

            const showMaterialMenuDebounce: () => void = debounce(() => {
                checkMaterialMenu();
            }, 300);

            const showSettingsMenuDebounce: () => void = debounce(() => {
                checkSettingsMenu();
            }, 300);

            const showProfileMenuDebounce: () => void = debounce(() => {
                checkProfileMenu();
            }, 300);

            const showSupportMenuDebounce: () => void = debounce(() => {
                checkSupportMenu();
            }, 300);
            //endregion

            // Закрытие меню при клике на закрепленные элементы
            function closeMenu(): void {
                if (isMobileWidth() && isVisibleLeftMenu.value) {
                    mainLayoutStore.isVisibleLeftMenu = false;
                }
            }

            //При клике на родительском пункте меню на десктопе - остановливаем обработку события,
            //чтобы не нарушить onmouseover. В мобильном виде оставляем все как есть,
            //иначе onmouseover там не сработает
            function onClickAtParent(event: Event, callback?: () => void): void {
                event.preventDefault();

                if (callback) {
                    callback();
                }

                if (isMobileWidth()) {
                    return;
                }

                event.stopPropagation();
            }

            // Получить ограниченное значение для непрочитанных сообщений и уведомлений
            function getLimitedUnreadCountEvents(count: number): string {
                return count < 100
                    ? count.toString()
                    : '99+';
            }

            function getClassForSelectedMenu(routeName: RoutePageNameEnum): string {
                return getRoutePageName(routeName) === currentPage.value ? 'selected-menu' : '';
            }

            function isCurrentPage(routeName: RoutePageNameEnum): boolean {
                return getRoutePageName(routeName) === currentPage.value;
            }

            function getRoutePageName(routePage: RoutePageNameEnum): string {
                return Common.getRouteName(routePage);
            }

            // В зависимости от ролей пользователя показываем нужны пункты меню
            function getUniversityMenuItems(): { title: string; page: number, isVisible?: boolean }[] {
                if (
                    Common.isHaveRole(RoleEnum.AuditorRole) ||
                    Common.isHaveRole(RoleEnum.CartographerRole) ||
                    Common.isHaveRole(RoleEnum.DivisionAdministratorRole) ||
                    Common.isHaveRole(RoleEnum.AuditorRole) ||
                    Common.isHaveRole(RoleEnum.EducationalProgramAdministratorRole) ||
                    Common.isHaveRole(RoleEnum.SuperAdministratorRole) ||
                    Common.isHaveRole(RoleEnum.TeacherRole) ||
                    Common.isHaveRole(RoleEnum.CohortAdministratorRole) ||
                    Common.isHaveRole(RoleEnum.UniversityAdministratorRole) ||
                    Common.isHaveRole(RoleEnum.UniversityContentManagerRole) ||
                    Common.isHaveRole(RoleEnum.GroupCuratorRole)
                ) {
                    return universityMenuItemsAll.filter((x) => x.isVisible);
                } else if (Common.isHaveRole(RoleEnum.StudentRole)) {
                    return universityMenuItemsForStudent.filter((x) => x.isVisible);
                }

                return [
                    {
                        title: localize('Организации'),
                        page: RoutePageNameEnum.Universities,
                    },
                ];
            }

            //region Управление показом меню
            function checkUniversityMenu(): void {
                isVisibleUniversityMenu.value = isUniversityMenuOver.value || isUniversityMenuListOver.value;
            }

            function checkMaterialMenu(): void {
                isVisibleMaterialMenu.value = isMaterialMenuOver.value || isMaterialMenuListOver.value;
            }

            function checkProfileMenu(): void {
                isVisibleProfileMenu.value = isProfileMenuOver.value || isProfileMenuListOver.value;
            }

            function checkSettingsMenu(): void {
                isVisibleSettingsMenu.value = isSettingsMenuOver.value || isSettingsMenuListOver.value;
            }

            function checkSupportMenu(): void {
                isVisibleSupportMenu.value = isSupportMenuOver.value || isSupportMenuListOver.value;
            }
            //endregion

            function onHide(): void {
                mainLayoutStore.isVisibleLeftMenu = false;
            }

            // Открепить дисциплину
            async function unpinDiscipline(id: number): Promise<void> {
                const result = await new DisciplineClient(getApiClientInitialParams()).unPinDiscipline(id);

                if (result.isSuccess) {
                    mainLayoutStore.removePinnedDiscipline(id);
                } else {
                    NotifyErrors(result);
                }
            }

            // Открепить подразделение
            async function unpinDivision(id: number): Promise<void> {
                const result = await new DivisionClient(getApiClientInitialParams()).unpinDivision(id);
                if (result.isSuccess) {
                    mainLayoutStore.removePinnedDivision(id);
                } else {
                    NotifyErrors(result);
                }
            }

            // Открепить поток
            async function unpinCohort(id: number): Promise<void> {
                const result = await new CohortClient(getApiClientInitialParams()).unpinCohort(id);
                if (result.isSuccess) {
                    mainLayoutStore.removePinnedCohort(id);
                } else {
                    NotifyErrors(result);
                }
            }

            // Открепить образовательную программу
            async function unpinProgram(id: number): Promise<void> {
                const result = await new EducationalProgramClient(getApiClientInitialParams()).unpinEducationalProgram(id);
                if (result.isSuccess) {
                    mainLayoutStore.removePinnedProgram(id);
                } else {
                    NotifyErrors(result);
                }
            }

            // Открыть страницу Соглашение
            function getAgreementLink(): string {
                return $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.Agreement),
                    query: { type: 'Agreement' },
                }).fullPath;
            }

            // Открыть страницу Конфиденциальность
            function getConfidentialityLink(): string {
                return $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.Agreement),
                    query: { type: 'Confidentiality' },
                }).fullPath;
            }

            // Открыть страницу Соглашение
            function toAgreement(): void {
                window.open(getAgreementLink(), '_blank');
            }

            // Открыть страницу Конфиденциальность
            function toConfidentiality(): void {
                window.open(getConfidentialityLink(), '_blank');
            }

            // Мобильно отображение начинается с 960px
            function isMobileWidth(): boolean {
                return window.innerWidth <= mobileWidthMax;
            }

            // На десктопе 80px, на мобилке - во всю ширину
            function setSize(): void {
                if (isMobileWidth()) {
                    width.value = window.innerWidth;
                    mainLayoutStore.isVisibleLeftMenu = false;
                } else {
                    width.value = 80;
                    mainLayoutStore.isVisibleLeftMenu = true;
                }
            }

            // Получить ссылку на страницу дисциплины
            function getDisciplinePageUrl(id: number): string {
                const route = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.DisciplineInfo),
                    params: { id: id.toString() },
                });
                return route.path;
            }

            // Открыть страницу дисциплины в новой вкладке или окне
            function openDisciplinePage(id: number, type: string = 'window'): void {
                const path = getDisciplinePageUrl(id);
                window.open(path, '_blank', type === 'window' ? 'location=yes,scrollbars=yes' : '');
            }

            // Открыть страницу подразделения в новой вкладке или окне
            function openDivisionPage(id: number, type: string = 'window'): void {
                const path = $router.resolve({
                    name: getDivisionRouteName,
                    params: { id: id.toString() }
                }).path;

                window.open(path, '_blank', type === 'window' ? 'location=yes,scrollbars=yes' : '');
            }

            // Открыть страницу программы в новой вкладке или окне
            function openProgramPage(id: number, type: string = 'window'): void {
                const path = $router.resolve({
                    name: getProgramRouteName,
                    params: { id: id.toString() }
                }).path;

                window.open(path, '_blank', type === 'window' ? 'location=yes,scrollbars=yes' : '');
            }

            // Открыть страницу потока в новой вкладке или окне
            function openCohortPage(id: number, type: string = 'window'): void {
                const path = $router.resolve({
                    name: getCohortRouteName,
                    params: { id: id.toString() }
                }).path;

                window.open(path, '_blank', type === 'window' ? 'location=yes,scrollbars=yes' : '');
            }

            // Получить ссылку на страницу
            function getPageUrl(item: RoutePageNameEnum): string {
                const itemName = Common.getRouteName(item);
                if (item === RoutePageNameEnum.Library) {
                    const path = $router.resolve({
                        name: itemName,
                        params: { path: [accountStore.getAccountInfo?.libraryId ?? ''] },
                    });
                    return path.path;
                } else if (item === RoutePageNameEnum.UserInfo) {
                    const path = $router.resolve({
                        name: itemName,
                        params: { id: accountStore.getAccountInfo?.id ?? 0 },
                    });
                    return path.path;
                } else {
                    const path = $router.resolve({ name: itemName });
                    return path.path;
                }
            }

            // При выходе из приложения трем данные в сторе и удаляем действующий fcm токен
            async function logOut(): Promise<void> {
                await app?.appContext.config.globalProperties.$fcmService?.removeToken();
                useAuthorizationStore().logout();
                accountStore.accountInfo = null;
                chatStore.unreadedMessagesCount = [];
                mainLayoutStore.isShowBackBreadcrumbsButton = false;
                mainLayoutStore.breadcrumbs = [];

                $router.push({
                    name: loginRouteName
                }).then(() => {
                    // Удаляем черновики пользователя ODIN-9662
                    chatStore.drafts = null;
                });
            }

            // Открыть компонент поиска
            function showSearchBlock(): void {
                mainLayoutStore.isVisibleSearchBlock = true;

                if (isMobileWidth()) {
                    mainLayoutStore.isVisibleLeftMenu = false;
                }
            }

            // Открыть блок с текущими звонками
            function showCalls(): void {
                mainLayoutStore.isVisibleCallsBlock = true;
                mainLayoutStore.isVisibleChatBlock = true;

                if (isMobileWidth()) {
                    mainLayoutStore.isVisibleLeftMenu = false;
                }
            }

            // Какой язык сейчас выбран
            function isActiveLang(locale: LocaleName): boolean {
                return LocalizationService.getLocaleName() === locale;
            }

            // Смена языка интерфейса
            async function setLocale(locale: LocaleName): Promise<void> {
                await setUserLanguage(locale);

                const currentLocale = LocalizationService.getLocaleName().toLocaleLowerCase();

                LocalizationService.setLocaleName(locale);

                if (currentLocale !== locale) {
                    location.href = location.href.replace(`/${currentLocale}/`, `/${locale}/`);
                }
            }

            // Установка языка пользователя
            async function setUserLanguage(locale: LocaleName): Promise<void> {
                const language = locale === LocaleName.EN ? LanguagesEnum.English : LanguagesEnum.Russian;

                const requestBody: UserSetLanguageRequest = {
                    language
                };

                const request: Result =
                    await new UserClient(getApiClientInitialParams()).setLanguage(requestBody);

                if (!request.isSuccess) {
                    NotifyErrors(request);
                }
            }

            async function loadPinnedPages(): Promise<void> {
                const result = await new UserClient(getApiClientInitialParams()).getPinnedPages();

                if (!result.isSuccess) {
                    NotifyErrors(result);
                    return;
                }

                mainLayoutStore.pinnedDivisions = result.entity.pinnedDivisions || [];
                mainLayoutStore.pinnedPrograms = result.entity.pinnedPrograms || [];
                mainLayoutStore.pinnedCohorts = result.entity.pinnedCohorts || [];
                mainLayoutStore.pinnedDisciplines = result.entity.pinnedDisciplines || [];
            }

            // Закрытие дополнительного меню у всех пунктов
            function closeMenuList(): void {
                isMaterialMenuOver.value = false;
                isUniversityMenuOver.value = false;
                isSettingsMenuOver.value = false;
                isProfileMenuOver.value = false;
                isViewTooltip.value = false;
                closeUnpinMenus();
            }

            function closeUnpinMenus(): void {
                if (app?.refs.unpinMenuRef) {
                    (app.refs.unpinMenuRef as QMenu[]).forEach((menu: QMenu) => {
                        menu.hide();
                    });
                }
            }

            // Если мы доскроили до конца, то скрываем блок с тенью внизу
            // тк он перекрывает элемент и он не кликается
            function onScroll({
                verticalContainerSize,
                verticalPercentage,
            }: {
                verticalContainerSize: number;
                verticalPercentage: number;
            }): void {
                // Убираем закрытие меню при скролле в мобильном меню, так как после открытия автоматом происходит скролл и меню тут же закрывается
                if (!isMobileWidth()) {
                    closeMenuList();
                }
                if (verticalContainerSize === scrollAreaRef.value?.getScrollTarget().scrollHeight) {
                    isViewScroll.value = true;
                } else {
                    isViewScroll.value = false;
                    if (verticalPercentage > 0.92) {
                        isViewScroll.value = true;
                    }
                }
                // Задержка нужна для то чтобы понять, что пользователь перестал скроллить
                // И теперь можно показывать подсказку
                nextTick(() => {
                    isViewTooltip.value = true;
                });
            }

            async function userUniversity(): Promise<void> {
                // Получаем университеты пользователя
                const request: ResultOf<CurrentUserInfoResponseModel> = await new UserClient(getApiClientInitialParams()).currentUserInfo();

                if (request.isSuccess) {
                    universityUser.value = request.entity.universitiesThreeConnected ?? [];
                }
            }

            onMounted(() => {
                loadPinnedPages();
                setSize();
                currentPage.value = $route.name ? $route.name.toString() : '';
                currentFullPath.value = $route.fullPath || '';

                // получаем количество непрочитанных оповещений
                notificationsStore.getUnreadedNotificationsCount();

                if (isMobileWidth()) {
                    isVisible.value = false;
                }

                userUniversity();

                if (rootElRef.value?.$el) {
                    rootElRef.value.$el.setAttribute('ariaHidden', 'true');
                    rootElRef.value.$el.firstChild.setAttribute('ariaHidden', 'true');
                }
            });

            return {
                unpinMenuRef,
                rootElRef,
                scrollAreaRef,
                isNewYear,
                changeCanaryLink,
                currentFullPath,
                getCohortRouteName,
                getDivisionRouteName,
                getProgramRouteName,
                loginRouteName,
                isVisible,
                isShowSchedule,
                isVisibleUniversityMenu,
                isUniversityMenuOver,
                isUniversityMenuListOver,
                isVisibleProfileMenu,
                isOnlyStudent,
                isProfileMenuOver,
                isProfileMenuListOver,
                isVisibleMaterialMenu,
                isMaterialMenuOver,
                isMaterialMenuListOver,
                isVisibleSettingsMenu,
                isSettingsMenuOver,
                isSettingsMenuListOver,
                isVisibleSupportMenu,
                isSupportMenuOver,
                isSupportMenuListOver,
                isViewScroll,
                universityUser,
                materialMenuItems,
                width,
                currentPage,
                isViewTooltip,
                // Цвета для дефолтных аватаров
                CustomAvatarColors,
                // Енам страниц для доступа к нему из шаблона
                RoutePageNameEnum,
                // Енам языка для доступа к нему из шаблона
                LocaleName,
                isDisabledUniversityMenu,
                divisions,
                programs,
                cohorts,
                disciplines,
                isCanary,
                isPageUniversity,
                isMaterials,
                isSettings,
                accountInfo,
                countUnreadedMessages,
                supportEmail,
                countUnreadedNotification,
                showMaterialRoles,
                isSuperAdmin,
                callNotifications,
                helpCenterLink,
                currentUserToLibraryPath,
                currentUserPage,
                supportFormLink,
                closeMenu,
                onClickAtParent,
                getRoutePageName,
                getUniversityMenuItems,
                onHide,
                unpinDiscipline,
                unpinDivision,
                unpinCohort,
                unpinProgram,
                getAgreementLink,
                toConfidentiality,
                setSize,
                getDisciplinePageUrl,
                openDisciplinePage,
                openDivisionPage,
                openProgramPage,
                openCohortPage,
                getPageUrl,
                logOut,
                showSearchBlock,
                showCalls,
                isActiveLang,
                setLocale,
                localize,
                onScroll,
                toAgreement,
                getConfidentialityLink,
                getClassForSelectedMenu,
                getLimitedUnreadCountEvents
            };
        }
    });
</script>

<style lang="scss" scoped src="./style.scss" />

<style lang="scss">
    .selected-menu {
        color: $shade-9;
    }

    .helper-menu {
        margin-top: -16px !important;
    }

    .left-menu-component {
        &.new-year {
            @media (min-width: 961px) {
                background-image: url('assets/left-menu-new-year.png');
                background-size: 100% auto;
            }
        }

        .q-scrollarea__thumb.q-scrollarea__thumb--v.absolute-right {
            display: none;
        }
    }

    .sub-menu__transition {
        transition: all 0s;
    }

    .q-menu {
        &.sub-menu {
            &.support-menu {
                margin-left: 80px !important;

                .small-item {
                    min-height: 36px;

                    &:first-child {
                        margin-top: 8px;
                    }

                    &:nth-child(2) {
                        margin-bottom: 4px;
                    }
                }
            }
        }

        .small-text-item {
            min-height: auto;
            font-size: 10px;
            line-height: 12px;
            color: $shade-8;
        }
    }

    .q-drawer--left {
        background-color: $shade-1;
    }

    .q-drawer--left,
    .q-drawer__backdrop {
        top: 51px;
    }

    @media (max-width: 960px) {
        .q-drawer--left {
            background-color: #fff;
        }

        .left-menu-component {
            .content {
                .logo {
                    display: none;
                }

                .menu-item {
                    max-width: 100%;
                    display: flex;
                    align-items: center;
                    margin-left: 16px;

                    a {
                        display: flex;
                        align-items: center;
                    }

                    &.support-menu {
                        border-top: 1px solid $shade-2;
                        padding-top: 18px;
                        margin: 0 auto;
                    }

                    &.only-mobile {
                        display: flex;
                    }

                    .menu-title {
                        margin-left: 16px;
                        font-size: 14px;
                        line-height: 22px;
                    }

                    .notify {
                        left: 16px !important;
                    }

                    .q-avatar {
                        svg {
                            path,
                            circle {
                                stroke: $shade-2 !important;
                            }
                        }
                    }
                }

                .menu-content {
                    height: calc(100% - 50px) !important;

                    &.open-support {
                        height: calc(100% - 220px) !important;
                    }
                }

                .language-block {
                    margin-left: 16px;
                }
            }
        }

        .sub-menu {
            display: none;
        }

        .sub-menu-mobile {
            display: block !important;
            width: 100%;

            .menu-item {
                margin-left: 32px !important;
            }
        }
    }
</style>
