import ValidationRulesText from 'src/helpers/ValidationRulesText';

export type ValidationRules = {
    isRequired: (val: string | null | undefined) => boolean | string;
    isRequiredID: (val: { id: string | number | null | undefined }) => boolean | string;
    isNumber: (val: string | number | null | undefined) => boolean | string;
    isRequiredIf: (conditionFunc: () => boolean) => (val: string | null | undefined) => boolean | string;
    isRequiredEmail: (val: string | null | undefined) => boolean | string;
    between: (val: string | number | null | undefined) => boolean | string;
    maxLength: (val: string | null | undefined, maximumLength: number) => boolean | string;
    minLength: (val: string | null | undefined, minimalLength: number) => boolean | string;
    maxValue: (val: string | null | undefined, maxValue: number) => boolean | string;
    greaterZeroIf: (conditionFunc: () => boolean) => (val: string | null | undefined) => boolean | string;
    greaterZero: (val: string | null | undefined) => boolean | string;
    isEqualPassword: (val: string | null | undefined, value: string) => boolean | string;
    isHttp: (val?: string | null) => boolean | string;
    isOnlyNumericSymbols: (val: string | number | null | undefined) => boolean | string;
    useWithMsg : (validationFunc: (val: string | null | undefined, ...args: any[]) => boolean | string, getMessageFunc: (val: string | null | undefined) => string) => (val: string | null | undefined,...args:any[]) => boolean | string;
};

// Правила валидации для поля rules
// При необходимости, список можно расширить
// eslint-disable-next-line max-lines-per-function
export default function useValidationRules(): ValidationRules {
    // Обязательное поле
    const isRequired = (val: string | null | undefined): boolean | string => {
        const value = val?.toString().trim();
        const rule = value !== undefined && value !== null && value !== '';

        return rule || ValidationRulesText.required;
    };

    // Обязательное поле id
    const isRequiredID = (val: { id: string | number | null | undefined }): boolean | string => {
        const value = val?.id?.toString().trim();
        const rule = value !== undefined && value !== null && value !== '';

        return rule || ValidationRulesText.required;
    };

    // Вводить можно только число
    const isNumber = (val: string | number | null | undefined): boolean | string => {
        if (!val) {
            return true;
        }

        return /^([0-9]+([,.]?[0-9]{1,2})?)$/.test(val.toString().trim()) || ValidationRulesText.number;
    };

    // Вводить можно только цифры
    const isOnlyNumericSymbols = (val: string | number | null | undefined): boolean | string => {
        if (!val) {
            return true;
        }

        return /^([0-9]+?)$/.test(val.toString().trim()) || ValidationRulesText.onlyNumericSymbols;
    };

    // Обязательное поле при условии что conditionFunc === true
    const isRequiredIf = (conditionFunc: () => boolean) => (val: string | null | undefined): boolean | string => {
        if (conditionFunc.call(null) === true) {
            return isRequired(val);
        } else {
            return true;
        }
    };

    const isRequiredEmail = (val: string | null | undefined): boolean | string => {
        const value = val?.toString().trim();
        const isMail = String(value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        const rule = value !== undefined && value !== null && value !== '' && isMail !== null;

        return rule || ValidationRulesText.email;
    };

    // Обязательное поле в диапазоне
    const between = (val: string | number | null | undefined): boolean | string => {
        const value = val?.toString().trim();
        const rule = value !== undefined && value !== null && value !== '' && Number(value) >= 0 && Number(value) <= 100000000;

        return rule || ValidationRulesText.between;
    };

    // Минимальная длина
    const minLength = (val: string | null | undefined, minimalLength: number): boolean | string => {
        if (!val) {
            return true;
        }

        return val.length >= minimalLength || ValidationRulesText.minLength(minimalLength);
    };

    // Минимальная длина
    const maxLength = (val: string | null | undefined, maximumLength: number): boolean | string => {
        if (!val) {
            return true;
        }

        return val.length <= maximumLength || ValidationRulesText.maxLength(maximumLength);
    };

    // Больше нуля условное
    const greaterZeroIf =  (conditionFunc: () => boolean) => (val: string | null | undefined): boolean | string => {
        if (conditionFunc.call(null) !== true) {
            return true;
        }

        return greaterZero(val);
    };

     // Больше нуля
    const greaterZero = (val: string | null | undefined): boolean | string => {
        if (isNaN(Number(val))) {
            return true;
        }

        return Number(val) > 0 || ValidationRulesText.greaterZero;
    };

    // Максимальное значение
    const maxValue = (val: string | null | undefined, maximumValue: number): boolean | string => {
        if (!val) {
            return true;
        }

        if (isNaN(Number(val))) {
            return ValidationRulesText.number;
        }

        return Number(val) <= maximumValue || ValidationRulesText.maxValue(maximumValue);
    };

    // Проверка на совпадение со значением
    const isEqualPassword = (val: string | null | undefined, value: string): boolean | string => {
        if (!val) {
            return true;
        }

        return val === value || ValidationRulesText.mustMatch;
    };

    const isHttp = (val?: string | null): boolean | string => {
        const value = val?.toString().trim();
        if (value) {
            const pattern = /^((http|https):\/\/)/;
            if (!pattern.test(value)) {
                return ValidationRulesText.notValidLink;
            }
        }
        return true;
    };

    // Выполнить функцию валидации, но подменить сообщение об ошибке. Сообщение можно вернуть функцией принимающей опционально валидируемое значение
    const useWithMsg = (validationFunc: (val: string | null | undefined, ...args: any[]) => boolean | string, getMessageFunc: (val: string | null | undefined) => string) => (val: string | null | undefined, ...args:any[]): boolean | string => {
        const validationResult = validationFunc.call(null, val, args);
        if (validationResult?.toString() === 'true') {
            return true;
        } else {
            return getMessageFunc.call(null, val);
        }
    };

    return {
        isRequired,
        isRequiredIf,
        isNumber,
        isRequiredEmail,
        isHttp,
        isRequiredID,
        between,
        maxLength,
        minLength,
        isEqualPassword,
        maxValue,
        greaterZeroIf,
        greaterZero,
        isOnlyNumericSymbols,
        useWithMsg,
    };
}
